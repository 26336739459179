import {Injectable} from '@angular/core';
import {environment} from '@Env/environment';
import {FeatureConfig} from '@Shared/interfaces/feature-config';
import {AbstractFeatureConfigProvider} from './abstract-feature-config-provider';
import {Observable, of} from 'rxjs';

@Injectable()
export class EnvironmentFeatureConfigProvider implements AbstractFeatureConfigProvider {
  getFeatureConfigs(): Observable<FeatureConfig> {
    return of({
      /* eslint-disable @typescript-eslint/naming-convention */
      appraisals_section_enabled: environment.enableAppraisalsSection,
      commission_schedule_enabled: environment.enableCommissionSchedule,
      dashboard_filtering_tabs_enabled: environment.enableDashboardFilteringTabs,
      enableRentalCma: environment.enableRentalCma,
      ival_report_enabled: environment.enableIValReport,
      new_search_enabled: environment.enableNewSearch,
      planning_development_enabled: environment.enablePlanningDevelopment,
      profile_centre_enabled: environment.enableCentre,
      property_estimate_enabled: environment.enablePropertyEstimate,
      radius_search_enabled: environment.enableRadiusSearch,
      rental_cma_enabled: environment.enableRentalCma,
      reporting_enabled: environment.enableReporting,
      abbreviation_search_enabled: environment.enableAbbreviationSearch,
      new_prepared_for_enabled: environment.enableNewPreparedFor,
      market_history_details_enabled: environment.enableMarketHistoryDetails,
      view_saved_tabs_enabled: environment.enableViewedSavedTabs,
      market_history_view_details_enabled: environment.enableMarketHistoryViewDetails,
      quick_links_enabled: environment.enableQuickLinks,
      persist_recent_searches_enabled: environment.enablePersistedRecentSearches,
      prospecting_enabled: environment.enableProspecting,
      map_zone_enabled: environment.enableMapZones,
      cpms_enabled: environment.enableCpms,
      order_documents_v2_enabled: environment.enableOrderDocumentsV2,
      folio_numbers_enabled: environment.enableFolioNumbers,
      certificate_of_title_summary_enabled: environment.enableCertificateofTitleSummary,
      building_name_search_enabled: environment.enableBuildingNameSearch,
      primary_address_search_enabled: environment.enablePrimaryAddressSearch,
      company_profile_detail_enabled: environment.enableCompanyProfileDetail,
      export_search_results_pdf_enabled: environment.enableExportSearchResultsPdf,
      marketing_contacts_enabled: environment.enableMarketingContacts ?? false,
      customize_digital_cma_page_enabled: environment.enableCustomiseDigitalCmaPage,
      cma_tracking_events_toggle_enabled: environment.enableCmaTrackingEventsToggle,
    });
  }
}

/* eslint-disable max-len */
import {documentTypesNZ} from '@Shared/constants/document-types-NZ';
import {EnvironmentConfiguration} from './interfaces/environment-configuration';
import {nzFilters} from './config/filters-config/filters-nz';
import {nzPropertyData} from './config/property-data-config/property-data-nz';
import {customOverlaysNZ} from './config/custom-overlay-config/custom-overlay-nz-acugis';
import {BasemapMapboxConfiguration} from './interfaces/basemap-mapbox-configuration';
import {dateFormat} from './config/date-format/date-format';
import {sortOptions} from './config/sort-options/sort-options';
import {COMMON_GREETINGS} from './config/common-greetings/common-greetings';
import {nzDisclaimers} from './config/disclaimers-config/disclaimers.nz';
import {ProductFeatureCode} from '@Shared/enums/product-feature-code';
import {BasemapAzureMapsConfiguration} from './interfaces/basemap-azuremaps-configuration';

const instanceName = 'valocitytest';
const geoServerBaseUri = 'https://valocity.webgis1.com/geoserver';

export const environment: EnvironmentConfiguration = {
  apiGatewayUri: 'https://appgateway.valocity-test.co.nz',
  apimUri: 'https://apim-valocity-dev.azure-api.net',
  renderServiceUri: 'render-nz/v1.0/test',
  auth: {
    instance: `https://${instanceName}.onmicrosoft.com`,
    issuer: `https://${instanceName}.b2clogin.com/a0d6867a-3a3b-4863-a8fa-d5ce8db990e4/v2.0/`,
    loginUrl: `https://${instanceName}.b2clogin.com/${instanceName}.onmicrosoft.com/b2c_1a_signin/oauth2/v2.0/authorize`,
    tokenEndpoint: `https://${instanceName}.b2clogin.com/${instanceName}.onmicrosoft.com/b2c_1a_signin/oauth2/v2.0/token`,
    discoveryDocumentUrl: `https://${instanceName}.b2clogin.com/${instanceName}.onmicrosoft.com/b2c_1a_signin/v2.0/.well-known/openid-configuration`,
    clientId: '8df9acd8-26ae-47ec-92ff-86d9c0f28128',
  },
  brandingLabel: 'Valocity Nexus',
  countryCode: 'NZ',
  localeID: 'en-NZ',
  currencyCode: 'NZD',
  timezone: 'Pacific/Auckland',
  logoSrc: 'assets/logos/Nexus PNGs/Nexus Blue-white.png',
  production: false,
  appInsights: {
    // For ai-realestatenz-test-aue-1
    connectionString:
      'InstrumentationKey=12e1828a-ed8e-4d2d-8df5-cc7b66c6122d;IngestionEndpoint=https://australiaeast-0.in.applicationinsights.azure.com/;LiveEndpoint=https://australiaeast.livediagnostics.monitor.azure.com/',
  },
  productId: 'D61E338C-035E-465F-92ED-D4953CFD2F40',
  productLink: 'https://www.valocityglobal.com',
  pinIconSrc: 'assets/icons/android-chrome-96x96.png',
  vendorClientId: '98A9B733-76E3-4B3E-98BC-D0D6EA5432B3',
  enableCentre: false,
  enableReporting: true,
  enableIValReport: true,
  enableCommissionSchedule: false,
  enableRadiusSearch: true,
  enablePropertyEstimate: true,
  enableAppraisalsSection: true,
  enablePlanningDevelopment: false,
  enableNewSearch: false,
  enableRentalCma: false,
  enableDashboardFilteringTabs: false,
  enableAbbreviationSearch: false,
  enableNewPreparedFor: false,
  enableMarketHistoryDetails: false,
  enableViewedSavedTabs: false,
  enableMarketHistoryViewDetails: false,
  enableQuickLinks: false,
  enablePersistedRecentSearches: false,
  enableProspecting: false,
  enableMapZones: false,
  enableCpms: false,
  enableOrderDocumentsV2: false,
  enableFolioNumbers: false,
  enableCertificateofTitleSummary: true,
  enablePriceRangeToggle: false,
  enableBuildingNameSearch: false,
  enablePrimaryAddressSearch: false,
  enableComprehensiveEstimate: false,
  enableCompanyProfileDetail: true,
  enableExportSearchResultsPdf: false,
  enableCustomiseDigitalCmaPage: false,
  enableCmaTrackingEventsToggle: false,
  imagesOptions: {
    listingsImagesOptions: {
      showOnComparableListings: true,
      showOnComparableSales: true,
      showOnPropertySummary: true,
      showOnSearchResults: true,
    },
  },
  propertyReport: {
    minComparableProperties: 0,
    maxComparableProperties: 0,
  },
  favicon: 'assets/icons/favicon.png',
  filters: nzFilters,
  propertyData: nzPropertyData,
  documentTypes: documentTypesNZ,
  basemaps: {
    defaultZoomLevel: 17,
    maps: [
      {
        provider: 'Mapbox',
        configuration: {
          attribution: `© <a href='https://www.mapbox.com/about/maps/'>Mapbox</a> © <a href='http://www.openstreetmap.org/copyright'>OpenStreetMap</a> <strong><a href='https://www.mapbox.com/map-feedback/' target='_blank'>Improve this map</a></strong>`,
          accessToken:
            // this is a designated token for Nexus Web in NZ TEST environment. please do not use this in any other environment or any other place.
            'pk.eyJ1IjoiYWpmb3N0ZXIiLCJhIjoiY2w4cnZoc2ppMjJscDNvcWpvOWYwbDlsYSJ9.V3mzxcaDWh0f5GtMvxet9g',
          styles: [
            {
              name: 'Road',
              url: 'https://api.mapbox.com/styles/v1/mapbox/streets-v12/tiles/{z}/{x}/{y}?access_token=',
              maxZoom: 22,
              maxNativeZoom: 22,
            },
            {
              name: 'Aerial',
              url: 'https://api.mapbox.com/styles/v1/mapbox/satellite-streets-v12/tiles/{z}/{x}/{y}?access_token=',
              maxZoom: 22,
              maxNativeZoom: 22,
            },
          ],
        } as BasemapMapboxConfiguration,
      },
      {
        provider: 'AzureMaps',
        configuration: {
          attribution: '',
          authType: 'anonymous',
          clientId: '7c1fcc37-0a3d-4c14-9c22-1629778bdf94',
          tiles: [
            {
              name: 'Aerial',
              tilesetId: 'microsoft.imagery',
              maxZoom: 22,
              maxNativeZoom: 19,
            },
          ],
        } as BasemapAzureMapsConfiguration,
      },
    ],
  },
  customOverlayMaps: customOverlaysNZ(geoServerBaseUri),
  mapProperties: {
    geoServerUrl: `${geoServerBaseUri}/NewZealand/ows`,
    typeName: 'nz_properties',
    property: 'legal_desc',
    geomProperty: 'geom',
  },
  disclaimers: nzDisclaimers,
  gstPercentage: 15,
  recentSalesFilterEnabledStates: [],
  mouseflowSourceURL: '//cdn.mouseflow.com/projects/cabb7505-1031-4c5b-a283-e03e00d49b63.js',
  mouseflowTrackingCodeEnabled: true,
  knowledgeBaseUrl: 'https://j8fg03rdjp13.valocityglobal.com/knowledge',
  dateFormat,
  sortOptions,
  launchDarklyClientSDKKey: '64e92b78c99dfa124d8b6a48',
  disabledOwnerLinkStates: [],
  labels: [
    {name: 'LocalSchoolsInCatchment', value: 'In Zone'},
    {name: 'TitleOrdering', value: 'Title Documents'},
  ],
  availableLocalSchoolTabs: ['Primary', 'Intermediate', 'Secondary', 'Composite'],
  greetings: COMMON_GREETINGS,
  prospectingStatisticsMaxNumber: 1000,
  productFeatures: [
    ProductFeatureCode.iValReport,
    ProductFeatureCode.titleCurrentSearch,
    ProductFeatureCode.titleGuaranteedSearch,
    ProductFeatureCode.titleHistoricSearch,
    ProductFeatureCode.titleInstrument,
  ],
  dashboardLogoTile: {
    title: '',
    link: '',
    logo: 'assets/logos/REINZLogo.png',
  },
  maxCoverLetterLength: 2000,
  storyLaneOptions: {
    homeComponentDemoUri: 'https://valocity.storylane.io/demo/glfdsxuthmnr',
  },
};

export enum FeatureFlags {
  profileCentreEnabled = 'profile_centre_enabled',
  reportingEnabled = 'reporting_enabled',
  iValReportEnabled = 'ival_report_enabled',
  commissionScheduleEnabled = 'commission_schedule_enabled',
  orderDocumentsEnabled = 'order_documents_enabled',
  radiusSearchEnabled = 'radius_search_enabled',
  propertyEstimateEnabled = 'property_estimate_enabled',
  appraisalsSectionEnabled = 'appraisals_section_enabled',
  planningDevelopmentEnabled = 'planning_development_enabled',
  newSearchEnabled = 'new_search_enabled',
  rentalCmaEnabled = 'rental_cma_enabled',
  dashboardFilteringTabsEnabled = 'dashboard_filtering_tabs_enabled',
  abbreviationSearchEnabled = 'abbreviation_search_enabled',
  newPreparedForEnabled = 'new_prepared_for_enabled',
  marketHistoryDetailsEnabled = 'market_history_details_enabled',
  viewSavedTabsEnabled = 'view_saved_tabs_enabled',
  marketHistoryViewDetailsEnabled = 'market_history_view_details_enabled',
  quickLinksEnabled = 'quick_links_enabled',
  persistRecentSearchesEnabled = 'persist_recent_searches_enabled',
  mouseflowTrackingCodeEnabled = 'mouseflow_tracking_code_enabled',
  prospectingEnabled = 'prospecting_enabled',
  mapZonesEnabled = 'map_zone_enabled',
  cpmsEnabled = 'cpms_enabled',
  orderDocumentsV2Enabled = 'order_documents_v2_enabled',
  folioNumbersEnabled = 'folio_numbers_enabled',
  certificateOfTitleSummaryEnabled = 'certificate_of_title_summary_enabled',
  buildingNameSearchEnabled = 'building_name_search_enabled',
  primaryAddressSearchEnabled = 'primary_address_search_enabled',
  companyProfileDetailEnabled = 'company_profile_detail_enabled',
  storylaneEnabled = 'storylane_enabled',
  exportSearchResultsPdfEnabled = 'export_search_results_pdf_enabled',
  enableMarketingContacts = 'marketing_contacts_enabled',
  customizeDigitalCmaPageEnabled = 'customise_digital_cma_page_enabled',
  cmaTrackingEventsToggleEnabled = 'cma_tracking_events_toggle_enabled',
}
